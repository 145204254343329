import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Helmet, HelmetProvider } from 'react-helmet-async'

import AuthLayout from './auth'
import MainLayout from './main'

const Layouts = {
  main: MainLayout,
  auth: AuthLayout,
}

const IndexLayout = ({ children }) => {
  const location = useLocation()
  const { pathname } = location
  const users = useSelector((state) => {
    return state.users
  })

  const getLayout = () => {
    if (/^\/auth(?=\/|$)/i.test(pathname)) {
      return 'auth'
    }
    return 'main'
  }

  const BootstrappedLayout = () => {
    const Container = Layouts[getLayout()]
    const { authorized } = users
    const isAuthLayout = getLayout() === 'auth'

    if (users.initialStateLoading) {
      return null
    }

    if (!isAuthLayout && !authorized) {
      return <Redirect to="/auth/login" />
    }

    if (isAuthLayout && authorized) {
      return <Redirect to="/" />
    }

    return <Container>{children}</Container>
  }

  return (
    <HelmetProvider>
      <Helmet titleTemplate="%s | GoExplore">
        <title>Admin</title>
      </Helmet>
      {BootstrappedLayout()}
    </HelmetProvider>
  )
}

export default IndexLayout
