import React from 'react'
import { Card, Layout, Typography } from 'antd'
import moment from 'moment'

const { Header, Footer, Content } = Layout

const AuthLayout = ({ children }) => {
  return (
    <Layout
      style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}
    >
      <Header style={{ textAlign: 'center', height: 'auto' }}>
        <Typography.Title
          level={1}
          style={{ color: '#ffffff', margin: 0, padding: '16px' }}
        >
          GoExplore.by
        </Typography.Title>
      </Header>
      <Content style={{ flexGrow: 1 }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '80vh',
            padding: '0 15px',
          }}
        >
          <Card
            title="Добро пожаловать!"
            style={{ width: '100%', maxWidth: '580px' }}
          >
            {children}
          </Card>
        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>
        © {moment().format('YYYY')} GoExplore.by. Все права защищены.
      </Footer>
    </Layout>
  )
}

export default AuthLayout
