import React from 'react'
import {
  GrHome,
  GrList,
  GrLocation,
  GrMapLocation,
  GrUser,
} from 'react-icons/gr'
import { Menu } from 'antd'

import { history } from '../../../../index'

const items = [
  {
    key: '/',
    label: 'Рабочий Стол',
    icon: (
      <GrHome
        className="white-svg"
        style={{ marginTop: '-2px', fontSize: '16px' }}
      />
    ),
  },
  {
    key: '/locations',
    label: 'Локации',
    icon: (
      <GrMapLocation
        className="white-svg"
        style={{ marginTop: '-2px', fontSize: '16px' }}
      />
    ),
    children: [
      {
        key: '/locations/categories',
        label: 'Категории',
        icon: (
          <GrList
            className="white-svg"
            style={{ marginTop: '-2px', fontSize: '16px' }}
          />
        ),
      },
      {
        key: '/locations/places/live',
        label: 'Места (Live)',
        icon: (
          <GrLocation
            className="white-svg"
            style={{ marginTop: '-2px', fontSize: '16px' }}
          />
        ),
      },
      {
        key: '/locations/places/draft',
        label: 'Места (Draft)',
        icon: (
          <GrLocation
            className="white-svg"
            style={{ marginTop: '-2px', fontSize: '16px' }}
          />
        ),
      },
    ],
  },
  {
    key: '/users',
    label: 'Пользователи',
    icon: (
      <GrUser
        className="white-svg"
        style={{ marginTop: '-2px', fontSize: '16px' }}
      />
    ),
  },
]

const SideMenu = () => {
  return (
    <Menu
      theme="dark"
      mode="inline"
      defaultSelectedKeys={['/']}
      onClick={(e) => {
        history.push(e.key)
      }}
      items={items}
    />
  )
}

export default SideMenu
