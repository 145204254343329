import React from 'react'
import { Layout, Typography, theme } from 'antd'
import moment from 'moment'
import SideMenu from './partials/SideMenu'

const { Header, Content, Footer, Sider } = Layout

const MainLayout = ({ children }) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken()

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        width={240}
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log(broken)
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type)
        }}
      >
        <div
          style={{
            textAlign: 'left',
            padding: '18px 12px',
          }}
        >
          <Typography.Title
            level={3}
            style={{
              margin: 0,
              padding: 0,
              color: colorBgContainer,
              marginBottom: '24px',
            }}
          >
            GoExplore
          </Typography.Title>
          <SideMenu />
        </div>
      </Sider>
      <Layout style={{ display: 'flex' }}>
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        />
        <Content
          style={{
            margin: '24px 16px 0',
            flexGrow: 1,
          }}
        >
          <div
            style={{
              padding: 24,
              minHeight: 360,
              borderRadius: 6,
              background: colorBgContainer,
            }}
          >
            {children}
          </div>
        </Content>
        <Footer
          style={{
            textAlign: 'center',
          }}
        >
          GoExplore.by © {moment().format('YYYY')}. Все права защищены.
        </Footer>
      </Layout>
    </Layout>
  )
}

export default MainLayout
