import { lazy } from 'react'

const LoginPage = lazy(() => import('../pages/auth/LoginPage'))
const DashboardPage = lazy(() => import('../pages/DashboardPage'))
const CategoriesPage = lazy(() => import('../pages/listings/CategoriesPage'))
const PlacesPage = lazy(() => import('../pages/listings/PlacesPage'))

const appRouter = [
  {
    path: '/auth/login',
    Component: LoginPage,
  },
  {
    path: '/',
    Component: DashboardPage,
  },
  {
    path: '/locations/categories',
    Component: CategoriesPage,
  },
  {
    path: '/locations/places/:slug',
    Component: PlacesPage,
  },
]

export default appRouter
